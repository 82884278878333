module.exports = [{
      plugin: require('/Users/samlarsen-disney/Documents/sld.codes/sld-hi-monzo/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/mango.png"},
    },{
      plugin: require('/Users/samlarsen-disney/Documents/sld.codes/sld-hi-monzo/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/samlarsen-disney/Documents/sld.codes/sld-hi-monzo/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-26978781-3","head":false},
    },{
      plugin: require('/Users/samlarsen-disney/Documents/sld.codes/sld-hi-monzo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
